import styled, { css } from "styled-components";
import media from "styled-media-query";

export const Container = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.white};
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: ${theme.border.radius};
    ${media.greaterThan("medium")`
      background: ${theme.colors.white};
      padding: ${theme.spacings.medium} ${theme.spacings.xlarge} ${theme.spacings.small};
    `}
  `}
`;

export const TitleContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${theme.spacings.small};
    border: 1px solid ${theme.colors.gray};
    padding: ${theme.spacings.xsmall};

    ${media.greaterThan("medium")`
      border: none;
      padding: 0;
    `}
  `}
`;

export const Title = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    font-size: 2.4rem;
    line-height: ${theme.spacings.medium};
  `}
`;

export const CloseButton = styled.div`
  ${({ theme }) => css`
    cursor: pointer;
    display: flex;
    align-items: center;
    border: 1px solid ${theme.colors.gray};
    border-radius: ${theme.border.radius};
    padding: 0.5rem;

    transition: filter ${theme.transition.default};
    &:hover {
      filter: brightness(0.8);
    }

    ${media.greaterThan("medium")`
      padding: 1rem;
    `}
  `}
`;

export const ContentContainer = styled.div`
  ${({ theme }) => css`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: 'space-between'
    background-color: #fff;
    overflow-y: auto;
    padding: 0 ${theme.spacings.xsmall} ${theme.spacings.xsmall};

    ::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;

    ${media.greaterThan("medium")`
      border: 1px solid #dbdbdb;
      border-radius: ${theme.border.radius};
      padding: ${theme.spacings.medium} 6.4rem;
    `}
  `}
`;

export const InnerContent = styled.div`
  width: 100%;

  ${media.greaterThan("medium")`
    max-width: 496px;
  `}
`;

export const Footer = styled.div`
  ${({ theme }) => css`
    display: none;
    align-items: center;
    justify-content: flex-end;
    font-size: ${theme.font.sizes.small};
    line-height: ${theme.spacings.small};
    color: #000000;
    margin-top: ${theme.spacings.small};

    span {
      margin-right: ${theme.spacings.xsmall};
    }

    ${media.greaterThan("medium")`
      display: flex;
    `}
  `}
`;
