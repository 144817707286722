import Modal from "components/Modal";
import CloseIcon from "templates/Nft/shared/icons/CloseIcon";
import { sanitizeToHtml } from "utils/sanitizeHtml";

import * as S from "./styles";

interface ModalProps {
  isOpen: boolean;
  handleClose: () => void;
  title: string;
  content: string;
}

const TextModal = ({ isOpen, handleClose, title, content }: ModalProps) => {
  const sanitizedContent = sanitizeToHtml(content);
  return (
    <Modal isOpen={isOpen} onClose={handleClose} closeOnClickOutside={true}>
      <S.Container className="text-modal">
        <S.TitleContainer className="text-modal__title-container">
          <S.Title className="text-modal__title">{title}</S.Title>
          <S.CloseButton aria-label="Close modal" onClick={handleClose}>
            <CloseIcon className="text-modal__close-icon" />
          </S.CloseButton>
        </S.TitleContainer>
        <S.ContentContainer className="text-modal__container">
          <S.InnerContent
            className="text-modal__inner-container"
            dangerouslySetInnerHTML={sanitizedContent}
          ></S.InnerContent>
        </S.ContentContainer>
      </S.Container>
    </Modal>
  );
};

export default TextModal;
