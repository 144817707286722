import styled, { css } from "styled-components";
import media from "styled-media-query";

interface ContentProps {
  color: "light" | "dark";
}

export const Content = styled.div<ContentProps>`
  ${({ theme, color = "white" }) => css`
    cursor: auto;
    color: ${theme.colors.black};
    z-index: ${theme.layers.alwaysOnTop + 20};
    box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04),
      0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04),
      0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: ${theme.border.radius};
    width: 100%;
    height: 100%;
    ${color === "light" && `background: ${theme.colors.white};`}
    ${color === "dark" && `background: ${theme.colors.black};`}
    max-width: 1200px;
  `}
`;

export const Overlay = styled.div`
  ${({ theme }) => css`
    background: rgba(0, 0, 0, 0.65);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: ${theme.layers.alwaysOnTop};
  `}
`;

type WrapperProps = {
  isOpen?: boolean;
  fullScreen: boolean;
};

const wrapperModifiers = {
  open: () => css`
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);
    visibility: visible;
  `,
  close: () => css`
    opacity: 0;
    pointer-events: none;
    transform: translateY(-2rem);
    visibility: hidden;
  `,
};

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, isOpen, fullScreen }) => css`
    background: transparent;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: ${theme.layers.alwaysOnTop + 10};
    display: flex;
    align-items: center;
    justify-content: center;

    ${media.greaterThan("medium") &&
    !fullScreen &&
    css`
      padding: ${theme.spacings.xsmall};
    `}

    ${fullScreen &&
    css`
      ${Content} {
        max-width: 100%;
      }
    `}

    ${isOpen && wrapperModifiers.open()}
    ${!isOpen && wrapperModifiers.close()}

    ${Content} {
      transition: transform 0.2s ease-in, opacity ${theme.transition.default};

      ${isOpen && wrapperModifiers.open()}
      ${!isOpen && wrapperModifiers.close()}
    }
  `}
`;
