import Logo from "components/Logo";
import TextModal from "components/TextModal";
import { Configuration } from "domain/configuration/types";
import { OrganizationFooter } from "domain/organization/types";
import NextLink from "next/link";
import { useState } from "react";

import * as S from "./styles";

export type FooterProps = {
  logoUrl?: string;
  footer?: OrganizationFooter;
  organizationName?: string;
  configuration: Configuration;
};

const Footer = ({
  logoUrl,
  footer,
  organizationName,
  configuration,
}: FooterProps) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  return (
    <>
      <S.Wrapper className="footer">
        <S.UpperContent className="footer_upper-content">
          <S.Column className="footer_column">
            <S.Title className="footer_title">{organizationName}</S.Title>
            <S.LinkContainer className="footer_link-container" tabIndex={0}>
              <a
                href={footer?.termsAndConditions}
                target="_blank"
                rel="noreferrer"
              >
                Terms &amp; Conditions
              </a>
            </S.LinkContainer>
            <S.LinkContainer
              className="footer_link-container"
              tabIndex={0}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  setModalOpen(true);
                  setTitle("Privacy Policy");
                  setContent(footer?.privacyPolicy || "");
                }
              }}
              onClick={() => {
                setModalOpen(true);
                setTitle("Privacy Policy");
                setContent(footer?.privacyPolicy || "");
              }}
            >
              <a>Privacy Policy</a>
            </S.LinkContainer>
          </S.Column>
          <S.Column className="footer_link-column">
            <S.Title className="footer_title">Support &amp; Contact</S.Title>
            <Link href={`mailto:${configuration.contactEmailAddress}`}>
              Contact us via email
            </Link>
            <S.LinkContainer className="footer_link-container">
              <a href={configuration.faqURL} target="_blank" rel="noreferrer">
                Check out our FAQ
              </a>
            </S.LinkContainer>
          </S.Column>
          <S.Column className="footer_link-column">
            <S.Title className="footer_title">Rare Goods</S.Title>
            <S.LinkContainer className="footer_link-container">
              <a
                href={configuration.termsAndConditionsURL}
                target="_blank"
                rel="noreferrer"
              >
                Terms &amp; Conditions
              </a>
            </S.LinkContainer>
            <S.LinkContainer className="footer_link-container">
              <a
                href={configuration.privacyPolicyURL}
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
            </S.LinkContainer>
          </S.Column>
        </S.UpperContent>
        <S.BottomContent className="footer_bottom-content">
          <Logo logoUrl={logoUrl} />
          <S.DesktopRights className="footer_desktop-rights">
            © 2021 Raregoods Inc. All Rights Reserved.
          </S.DesktopRights>
          <S.IconsContainer></S.IconsContainer>
        </S.BottomContent>
        <S.MobileRights className="footer_mobile-rights">
          © 2021 Raregoods Inc. All Rights Reserved.
        </S.MobileRights>
      </S.Wrapper>
      <TextModal
        isOpen={isModalOpen}
        handleClose={() => setModalOpen(false)}
        title={title}
        content={content}
      ></TextModal>
    </>
  );
};

export default Footer;

type LinkProps = {
  href: string;
  children: React.ReactNode;
};

const Link = ({ href, children }: LinkProps) => (
  <S.LinkContainer className="footer_link-container">
    <NextLink href={href}>{children}</NextLink>
  </S.LinkContainer>
);
