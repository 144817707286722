import useHideScroll from "hooks/useHideScroll";

import * as S from "./styles";

export type ModalProps = {
  children: React.ReactNode;
  isOpen: boolean;
  fullScreen?: boolean;
  color?: "light" | "dark";
  closeOnClickOutside?: boolean;
  onClose: () => void;
};

const Modal = ({
  isOpen,
  onClose,
  children,
  closeOnClickOutside = true,
  color = "light",
  fullScreen = false,
}: ModalProps) => {
  useHideScroll(isOpen);

  const handleClickOutside = () => {
    if (closeOnClickOutside) {
      onClose();
    }
  };

  return (
    <S.Wrapper
      aria-hidden={!isOpen}
      isOpen={isOpen}
      className="modal"
      fullScreen={fullScreen}
    >
      <S.Content aria-hidden={!isOpen} className="modal__content" color={color}>
        {children}
      </S.Content>
      <S.Overlay
        aria-hidden={!isOpen}
        onClick={handleClickOutside}
        className="modal__overlay"
      />
    </S.Wrapper>
  );
};

export default Modal;
