import styled, { css } from "styled-components";
import media from "styled-media-query";

export const Wrapper = styled.footer`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: ${theme.spacings.xsmall};
    padding-bottom: ${theme.spacings.medium};

    ${media.greaterThan("medium")`
      padding: ${theme.spacings.medium} ${theme.spacings.large};
    `}
  `}
`;

export const Column = styled.div`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.colors.lightGray};
    padding: ${theme.spacings.xsmall} 0;

    ${media.greaterThan("medium")`
      border-bottom: none;
    `}
  `}
`;

export const UpperContent = styled.div`
  display: flex;
  flex-direction: column;

  ${media.greaterThan("medium")`
    flex-direction: row;

    ${Column}:first-child{
      width: 30%;
    }

    ${Column}:nth-child(2) {
      flex: 1;
    }
  `}
`;

export const Title = styled.span`
  ${({ theme }) => css`
    display: block;
    text-transform: uppercase;
    font-size: ${theme.font.sizes.small};
    line-height: ${theme.spacings.xsmall};
    margin-bottom: ${theme.spacings.xsmall};
    letter-spacing: 0.2em;
  `}
`;

export const LinkContainer = styled.span`
  ${({ theme }) => css`
    display: block;

    a {
      font-size: ${theme.font.sizes.small};
      line-height: ${theme.spacings.small};
      text-decoration: none;
      letter-spacing: 0.0025em;
      color: ${theme.colors.black};
      cursor: pointer;
    }
  `}
`;

export const BottomContent = styled.div`
  padding-top: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  ${media.greaterThan("medium")`
    margin-bottom: 0;
    > * {
      text-align: center;
      flex-basis: 0;
      flex-shrink: 1;
      flex-grow: 1;
    }
  `}
`;

export const LogoContainer = styled.div`
  width: 52px;
  height: 52px;
`;

export const Rights = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.small};
    line-height: ${theme.spacings.small};
    letter-spacing: 0.0025em;
    color: ${theme.colors.black};
  `}
`;

export const DesktopRights = styled(Rights)`
  display: none;
  justify-content: center;

  ${media.greaterThan("medium")`
    text-align: center;
    display: initial;
  `}
`;

export const MobileRights = styled(Rights)`
  justify-content: center;

  ${media.greaterThan("medium")`
    display: none;
  `}
`;

export const IconWrapper = styled.button.attrs({
  className: "footer__icon-wrapper",
})`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${theme.colors.black};
    background-color: transparent;
    cursor: pointer;
    border: none;

    transition: filter ${theme.transition.default};
    &:hover {
      filter: brightness(0.9);
    }
  `}
`;

export const IconsContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 1rem;
    align-items: center;

    ${IconWrapper}:not (: first-child) {
      margin-left: ${theme.spacings.xsmall};
    }

    ${media.greaterThan("medium")`
      justify-content: flex-end;

      ${IconWrapper}:not(:first-child){
        margin-left: ${theme.spacings.medium};
      }
    `}
  `}
`;
